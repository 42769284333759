import React from 'react';
import {Warning} from '@mui/icons-material';
import {Backdrop} from '@mui/material';
import {SIZES, Typography, Button, COLORS} from '@fupa/fupa-uikit';
import styled from 'styled-components';
import {useNative} from 'app/contexts/NativeContext';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${SIZES[32]};
  background-color: ${COLORS.white};
  border-radius: ${SIZES[4]};
  margin: 0 10%;
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${SIZES[24]};
  width: 100%;
`;

const StyledTypography = styled(Typography)`
  text-align: center;
`;

export const PushBlockedAlert = ({open, onClose}) => {
  const {isNativeApp, openDeviceSettings} = useNative();
  const handleClick = () => {
    if (isNativeApp) {
      onClose();
      openDeviceSettings.handler();
    } else {
      onClose();
    }
  };
  return (
    <Backdrop open={open} sx={theme => ({zIndex: theme.zIndex.backdrop, backgroundColor: 'rgba(0, 0, 0, 0.6)'})}>
      <Wrapper>
        <Warning sx={{color: COLORS.yellowCard, fontSize: SIZES[40], marginBottom: SIZES[16]}} />
        <StyledTypography variant='body1'>
          Benachrichtigungen für das aktuelle Gerät sind durch Geräte-Einstellungen blockiert.
        </StyledTypography>
        <Actions>
          <Button label='Schließen' onClick={onClose} text secondary />
          <Button label='Einstellungen' onClick={handleClick} />
        </Actions>
      </Wrapper>
    </Backdrop>
  );
};
