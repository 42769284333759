import React from 'react';
import styled from 'styled-components';
import {AdSlot} from 'app/components/ads/AdSlot';

const SCAdSlot = styled(AdSlot)`
  &&& {
    margin: ${props => (props.margin ? props.margin : 0)};
  }
`;

const MobileBannerAdSlot = props => {
  return <SCAdSlot adLayout='mobile' {...props} adUnit='Mobile_Banner' />;
};

export default MobileBannerAdSlot;
