import {useEffect, useState} from 'react';
import {postClicks} from 'app/services/api';
import * as Sentry from '@sentry/react';
import {TimeoutMessage} from 'app/components/error/TimeoutMessage';

const useClickTracking = (entity, data, defaultValue = {clicks: 0}) => {
  const [clicks, setClicks] = useState(defaultValue);
  useEffect(() => {
    if (data.id) {
      postClicks(entity, data.id)
        .then(response => {
          if (response.data) {
            setClicks(response.data);
          }
        })
        .catch(e => {
          if (TimeoutMessage.test(e.message)) {
            // timeouts should not be caught for that
            return;
          }
          const scope = Sentry.getCurrentScope();
          scope.setContext('click-tracking', {entity, id: data.id});
          scope.setTag('fupa-debug', 'click');
          Sentry.captureException(e);
        });
    }
  }, [data]);
  return clicks;
};

export {useClickTracking};
