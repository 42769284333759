import React, {useEffect, useState} from 'react';
import {updateFollowEntity, updatePushEntity} from 'app/services/follow';
import {useDispatch, useSelector} from 'react-redux';
import {fetchFollows} from 'app/actions/currentUserActions';
import {selectFollowedEntities, selectCurrentUser} from 'app/hooks/reduxCreateSelectorHooks';
import {updateSnackbarInfo} from 'app/components/snackbar/SnackbarActions';
import {
  commonUpdateError,
  pushMatchError,
  unauthorizedFollow,
  limitError,
} from 'app/components/snackbar/SnackbarContent';
import {Notifications, NotificationsOutlined} from '@mui/icons-material';
import styled from 'styled-components';
import {PushBlockedAlert} from 'app/components/banner/PushBlockedAlert';
import {useHydration} from 'app/hooks/useHydration';
import {Typography} from '@fupa/fupa-uikit';
import {useNative} from 'app/contexts/NativeContext';

const PushButton = styled.div``;

const MatchPushButton = ({currentEntityId, parentClicked, className, component, hasLabel, muiClasses}) => {
  const listOfFollowedEntites = useSelector(selectFollowedEntities);
  const {notificationPermission} = useSelector(selectCurrentUser);
  const {isNativeApp} = useNative();
  const isHydrating = useHydration();
  const dispatch = useDispatch();

  const currentEntityFollowed = listOfFollowedEntites.find(entity => entity.id === currentEntityId);
  const [isPushed, setIsPushed] = useState(false);
  const [isInit, setIsInit] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setIsPushed(!!currentEntityFollowed);
  }, [currentEntityFollowed]);

  useEffect(() => {
    // Toggle push on click by parent element
    if (!isInit) {
      togglePush();
    }
    setIsInit(false);
  }, [parentClicked]);

  const togglePush = () => {
    const entityId = currentEntityFollowed ? currentEntityFollowed.id : currentEntityId;
    if (!!currentEntityFollowed) {
      setIsPushed(false);
      updateFollowEntity('match', entityId, true)
        .then(() => dispatch(fetchFollows()))
        .catch(() => {
          dispatch(updateSnackbarInfo(commonUpdateError));
          setIsPushed(true);
        });
    } else {
      if (isNativeApp && notificationPermission === 'denied') {
        setOpen(true);
      } else {
        setIsPushed(true);
        updatePushEntity('match', entityId, true)
          .then(() => {
            dispatch(fetchFollows());
          })
          .catch(err => {
            const name = 'des Spiels';
            let info = pushMatchError;
            if (err.response?.status === 400 && err.response?.data?.error?.translated_message) {
              info = limitError(err.response?.data?.error?.translated_message);
            }
            if (err.response?.status === 401 || err.status === 401) {
              info = unauthorizedFollow(name, 'Pushen');
            }
            dispatch(updateSnackbarInfo(info));
            setIsPushed(false);
          });
      }
    }
  };

  const icon = isPushed ? <Notifications color={'secondary'} /> : <NotificationsOutlined color={'action'} />;

  const showPushBlockedAlert =
    !isHydrating && isNativeApp ? <PushBlockedAlert open={open} onClose={() => setOpen(false)} /> : null;

  const label = hasLabel ? <Typography variant={isPushed ? 'button-secondary' : 'button-sub'}>PUSH</Typography> : null;
  return (
    <>
      <PushButton as={component} sx={muiClasses} className={className} onClick={togglePush}>
        {icon}
        {label}
      </PushButton>
      {showPushBlockedAlert}
    </>
  );
};

export {MatchPushButton};
