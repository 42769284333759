import React, {useEffect, useState} from 'react';
import {Backdrop, IconButton, Portal} from '@mui/material';
import styled from 'styled-components';
import {bool, func, string, object} from 'prop-types';
import {COLORS, Image, SIZES, Typography} from '@fupa/fupa-uikit';
import {zIndexBackdrop} from 'app/styles/cssConstants';
import SwipeableViews from 'react-swipeable-views';
import {Close, KeyboardArrowLeft, KeyboardArrowRight} from '@mui/icons-material';
import {getUserAgentData} from 'app/helpers/helpers';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: ${props => props.theme.desktopContentWidth}px;
  max-height: ${props => props.theme.desktopContentWidth}px;
  margin: auto;
`;

const Description = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${SIZES[8]} ${SIZES[16]} ${SIZES[24]};
  background-color: rgba(0, 0, 0, 0.5);
`;

const StyledTypography = styled(Typography)`
  &&& {
    color: ${COLORS.white};
  }
`;

const ImageContainer = ({entity, imageSrc}) => {
  const description =
    imageSrc && imageSrc.description ? (
      <Description>
        <StyledTypography variant='body1'>{imageSrc.description}</StyledTypography>
      </Description>
    ) : null;

  return (
    <Wrapper>
      <Image entity={entity} src={imageSrc} actualWidthFactor={1} ratio='original' fullscreen={true} fit={true} />
      {description}
    </Wrapper>
  );
};

const FullscreenImage = ({entity, imageSrc, onClick, open}) => {
  return (
    <Backdrop sx={{zIndex: zIndexBackdrop, backgroundColor: COLORS.black}} open={!!open} onClick={onClick}>
      <ImageContainer entity={entity} imageSrc={imageSrc} />
    </Backdrop>
  );
};

FullscreenImage.propTypes = {
  entity: string.isRequired,
  imageSrc: object.isRequired,
  onClick: func.isRequired,
  open: bool,
};

const FullScreenImageSlider = ({entity, images, startIndex, open, handleClose, callback}) => {
  if (!open) {
    return null;
  }

  const {deviceType} = getUserAgentData();
  const isDesktop = deviceType === 'desktop';

  const [activeStep, setActiveStep] = useState(startIndex);
  const maxSteps = images.length;

  useEffect(() => {
    callback(activeStep);
  }, [activeStep]);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleStepChange = step => {
    setActiveStep(step);
  };

  const imageSlides = images.map(image => {
    const imageSrc = image.image;
    return <ImageContainer entity={entity} imageSrc={imageSrc} />;
  });

  const buttonStyles = isDesktop => {
    return {
      margin: isDesktop ? '1rem 2rem' : '1rem',
      position: 'fixed',
      // Adjust Color of Stepper-Buttons to show button clearly if placed on image (using black) and on backdrop (using white)
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
      },
    };
  };

  const stepperButtonStyles = {
    // Adjust Color of Stepper-Buttons to show button clearly if placed on image (using black) and on backdrop (using white)
    '@media (min-width: 1130px)': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
      },
    },
  };

  const desktopStyles = buttonStyles(true);
  const mobileStyles = buttonStyles(false);
  const desktopControlsBtnNext = isDesktop ? (
    <IconButton
      sx={{...desktopStyles, ...stepperButtonStyles, right: 0}}
      size='large'
      onClick={handleNext}
      disabled={activeStep === maxSteps - 1}>
      <KeyboardArrowRight sx={{color: COLORS.white}} />
    </IconButton>
  ) : null;
  const desktopControlsBtnBack = isDesktop ? (
    <IconButton
      sx={{...desktopStyles, ...stepperButtonStyles, left: 0}}
      size='large'
      onClick={handleBack}
      disabled={activeStep === 0}>
      <KeyboardArrowLeft sx={{color: COLORS.white}} />
    </IconButton>
  ) : null;
  const btnClose = isDesktop ? (
    <IconButton sx={{...desktopStyles, left: 0, top: 0}} size='large' onClick={handleClose}>
      <Close sx={{color: COLORS.white}} />
    </IconButton>
  ) : (
    <IconButton sx={{...mobileStyles, left: 0, top: 0}} size='medium' onClick={handleClose}>
      <Close sx={{color: COLORS.white}} />
    </IconButton>
  );

  return (
    <Portal container={() => document.getElementById('modal-root')}>
      <Backdrop sx={{zIndex: zIndexBackdrop, backgroundColor: COLORS.black}} open={!!open}>
        <SwipeableViews
          style={{height: '100%', width: '100%'}}
          containerStyle={{height: '100%', width: '100%'}}
          enableMouseEvents={true}
          index={activeStep}
          onChangeIndex={handleStepChange}>
          {imageSlides.map(slide => slide)}
        </SwipeableViews>
        {btnClose}
        {activeStep === 0 ? null : desktopControlsBtnBack}
        {activeStep === maxSteps - 1 ? null : desktopControlsBtnNext}
      </Backdrop>
    </Portal>
  );
};

export {FullscreenImage, FullScreenImageSlider};
